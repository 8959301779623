.search-form {
  display: flex;
  align-items: center;
}

.input-container {
  display: flex;
  flex: 1;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px 0 0 8px;
  width: 100%;
}

.input-wrapper {
  display: flex;
  flex: 3;
  position: relative;
  width: 65%;
}

.search-input {
  border: none;
  padding: 12px;
  flex: 3;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.dropdown {
  display: none;
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
}

.dropdown-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.no-results {
  padding: 10px;
  color: #999;
}

.zip-container {
  display: flex;
  position: relative;
  /* margin-left: 8px; */
  flex: 1;
  width: 35%;
}

.zip-icon {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.zip-input {
  padding-left: 28px !important
  
  ;
  flex-grow: 1;
  /* border-radius: 0 8px 8px 0; */
  border-color: #ccc;
  border-width: 0 0 0 1px;
  width: 100%;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.search-button {
  padding: 9px 16px;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  border-radius: 0 8px 8px 0;
  /* margin-left: 8px; */

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.search-button:hover {
  background-color: var(--primary-color);
}

.search-button:focus {
  outline: none;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.search-button2 {
  padding:13px 20px;
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  border-radius: 0 8px 8px 0;
  /* margin-left: 8px; */

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.search-button2:hover {
  background-color: var(--primary-color);
}

.search-button2:focus {
  outline: none;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.button-content {
  display: flex;
  align-items: center;
}

.button-icon svg {
  width: 18px;
  height: 18px;
  fill: currentColor;
}
.search-button:focus {
  outline: none;
}
.search-input:focus-visible,
.zip-input:focus-visible {
  outline: none;
  border: none;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.search_container {
  top: 15%;
}
.search_btn_mobile {
  background-color: var(--primary-color);
  color: white;
}

.responsive-box {
  width: 50%;
}

@media (max-width: 768px) {
  .responsive-box {
    width: 100%;
  }
}
@media (min-width:768px)  and (max-width: 992px)  {
  .responsive-box {
    width: 75%;
  }
}